// Generated by Framer (c01e615)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Bu1uFCzUX"];

const variantClassNames = {Bu1uFCzUX: "framer-v-xknjnl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, width, ...props}) => { return {...props, fMGJDjkdZ: image ?? props.fMGJDjkdZ ?? {src: new URL("assets/CvXZ36RX9QA5c3Ha9nApbRK4n1Y.png", import.meta.url).href}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, fMGJDjkdZ, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Bu1uFCzUX", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Fl3i8", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 1500, intrinsicWidth: 2000, pixelHeight: 1500, pixelWidth: 2000, sizes: "min(359px, 100vw)", ...toResponsiveImage(fMGJDjkdZ)}} className={cx("framer-xknjnl", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Bu1uFCzUX"} ref={ref} style={{...style}}><Image background={{alt: "", fit: "fill", intrinsicHeight: 16, intrinsicWidth: 16, pixelHeight: 32, pixelWidth: 32, sizes: "max(max(min(359px, 100vw), 0px), 100px)", ...toResponsiveImage(fMGJDjkdZ)}} className={"framer-1fqfnx1"} data-framer-name={"favicon"} layoutDependency={layoutDependency} layoutId={"ZcuxrU4Yg"}/></Image></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Fl3i8 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Fl3i8 .framer-1yfd2xv { display: block; }", ".framer-Fl3i8 .framer-xknjnl { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; padding: 0px 0px 0px 0px; position: relative; width: 359px; }", ".framer-Fl3i8 .framer-1fqfnx1 { aspect-ratio: 1 / 1; flex: 1 0 0px; height: var(--framer-aspect-ratio-supported, 359px); min-height: 100%; min-width: 100px; overflow: visible; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Fl3i8 .framer-xknjnl { gap: 0px; } .framer-Fl3i8 .framer-xknjnl > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-Fl3i8 .framer-xknjnl > :first-child { margin-left: 0px; } .framer-Fl3i8 .framer-xknjnl > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 359
 * @framerIntrinsicWidth 359
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"fMGJDjkdZ":"image"}
 */
const FramerajsoebjGv: React.ComponentType<Props> = withCSS(Component, css, "framer-Fl3i8") as typeof Component;
export default FramerajsoebjGv;

FramerajsoebjGv.displayName = "ImagePlaceholder";

FramerajsoebjGv.defaultProps = {height: 359, width: 359};

addPropertyControls(FramerajsoebjGv, {fMGJDjkdZ: {__defaultAssetReference: "data:framer/asset-reference,CvXZ36RX9QA5c3Ha9nApbRK4n1Y.png?originalFilename=favicon.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerajsoebjGv, [])